var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "b-row",
        { staticClass: "mb-3", attrs: { "align-v": "center" } },
        [
          _c(
            "b-col",
            { attrs: { cols: "6" } },
            [
              _c("b-form-input", {
                attrs: { placeholder: "" + _vm.$t("admin.bundles.name") },
                model: {
                  value: _vm.bundle.bundleName,
                  callback: function($$v) {
                    _vm.$set(_vm.bundle, "bundleName", $$v)
                  },
                  expression: "bundle.bundleName"
                }
              })
            ],
            1
          ),
          _c(
            "b-col",
            [
              _c(
                "b-button",
                {
                  attrs: { size: "sm", variant: "primary" },
                  on: {
                    click: function($event) {
                      _vm.phaseQuantity++
                    }
                  }
                },
                [_vm._v(" " + _vm._s(_vm.$t("admin.bundles.addphase")) + " ")]
              )
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            _vm._l(_vm.phaseQuantity, function(n) {
              return _c("BundlePhase", {
                key: n,
                staticClass: "mb-3",
                attrs: {
                  quantity: _vm.phaseQuantity,
                  index: n,
                  "editing-phases": _vm.editingPhases
                },
                on: { updatePhase: _vm.updateBundlePhase }
              })
            }),
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "b-card",
    {
      attrs: { "border-variant": "primary", "header-bg-variant": "primary" },
      scopedSlots: _vm._u([
        {
          key: "header",
          fn: function() {
            return [
              _c(
                "b-row",
                { attrs: { "align-v": "center" } },
                [
                  _c("b-col", { attrs: { cols: "10" } }, [
                    _c("h6", { staticClass: "text-white mb-0" }, [
                      _vm._v(
                        _vm._s(_vm.$t("admin.bundles.phase")) +
                          " " +
                          _vm._s(_vm.index)
                      )
                    ])
                  ]),
                  _c(
                    "b-col",
                    { staticClass: "text-end", attrs: { cols: "2" } },
                    [
                      _c(
                        "b-form-checkbox",
                        {
                          staticClass: "text-white mb-0",
                          attrs: { switch: "" },
                          model: {
                            value: _vm.bundlePhase.optional,
                            callback: function($$v) {
                              _vm.$set(_vm.bundlePhase, "optional", $$v)
                            },
                            expression: "bundlePhase.optional"
                          }
                        },
                        [
                          _vm._v(
                            " " + _vm._s(_vm.$t("admin.bundles.optional")) + " "
                          )
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c(
        "b-card-text",
        [
          _c(
            "b-row",
            { staticClass: "mb-3" },
            [
              _c("b-col", { attrs: { cols: "3" } }, [
                _vm._v("Percentages for the Phase:")
              ]),
              _c(
                "b-col",
                { attrs: { cols: "9" } },
                [
                  _c(
                    "b-input-group",
                    {
                      attrs: {
                        prepend: "O: " + _vm.percentageOrigin.toString() + "%",
                        append:
                          "D: " + (100 - _vm.percentageOrigin).toString() + "%"
                      }
                    },
                    [
                      _c("b-form-input", {
                        attrs: {
                          id: "range-2",
                          number: "",
                          type: "range",
                          min: "0",
                          max: "100"
                        },
                        model: {
                          value: _vm.percentageOrigin,
                          callback: function($$v) {
                            _vm.percentageOrigin = $$v
                          },
                          expression: "percentageOrigin"
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _c(
            "b-alert",
            { attrs: { variant: "danger", show: _vm.showProductAlert } },
            [
              _c("b-icon", {
                staticClass: "mr-3",
                attrs: { icon: "exclamation-circle-fill", "font-scale": "1" }
              }),
              _vm._v(" " + _vm._s(_vm.$t("admin.bundles.alert")) + " ")
            ],
            1
          ),
          _c("b-table", {
            staticClass: "mb-5",
            attrs: {
              items: _vm.bundlePhase.products,
              fields: _vm.fields,
              responsive: "",
              small: "",
              bordered: ""
            },
            scopedSlots: _vm._u([
              {
                key: "thead-top",
                fn: function() {
                  return [
                    _c(
                      "b-tr",
                      [
                        _c(
                          "b-th",
                          {
                            staticClass: "text-center",
                            attrs: { colspan: "2" }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("admin.bundles.branches")) +
                                " "
                            )
                          ]
                        ),
                        _c(
                          "b-th",
                          {
                            staticClass: "text-center",
                            attrs: { colspan: "2" }
                          },
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("admin.bundles.percentages")) +
                                " "
                            )
                          ]
                        )
                      ],
                      1
                    )
                  ]
                },
                proxy: true
              },
              {
                key: "head()",
                fn: function(ref) {
                  var label = ref.label
                  return [
                    _c("p", { staticClass: "text-center align-middle" }, [
                      _vm._v(_vm._s(label))
                    ])
                  ]
                }
              },
              {
                key: "cell(isSingleBusiness)",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _c("b-icon", {
                      staticClass: "icon",
                      attrs: {
                        icon: item.isSingleBusiness ? "check-lg" : "x-lg",
                        variant: item.isSingleBusiness ? "success" : "danger"
                      }
                    })
                  ]
                }
              },
              {
                key: "cell(originBranches)",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _c(
                      "ul",
                      { staticClass: "list-inline d-inline-block mb-2" },
                      _vm._l(item.originBranches, function(tag) {
                        return _c(
                          "li",
                          { key: tag, staticClass: "list-inline-item" },
                          [
                            _c(
                              "b-form-tag",
                              {
                                attrs: {
                                  title: tag,
                                  variant: "warning",
                                  "no-remove": ""
                                }
                              },
                              [_vm._v(" " + _vm._s(tag) + " ")]
                            )
                          ],
                          1
                        )
                      }),
                      0
                    )
                  ]
                }
              },
              {
                key: "cell(destinationBranches)",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _c(
                      "ul",
                      { staticClass: "list-inline d-inline-block mb-2" },
                      _vm._l(item.destinationBranches, function(tag) {
                        return _c(
                          "li",
                          { key: tag, staticClass: "list-inline-item" },
                          [
                            _c(
                              "b-form-tag",
                              {
                                attrs: {
                                  title: tag,
                                  variant: "info",
                                  "no-remove": ""
                                }
                              },
                              [_vm._v(" " + _vm._s(tag) + " ")]
                            )
                          ],
                          1
                        )
                      }),
                      0
                    )
                  ]
                }
              },
              {
                key: "cell(price)",
                fn: function(ref) {
                  var item = ref.item
                  return [_vm._v(" " + _vm._s(_vm.getCurrency(item)) + " ")]
                }
              },
              {
                key: "cell(actions)",
                fn: function(ref) {
                  var item = ref.item
                  return [
                    _c(
                      "b-button",
                      {
                        staticClass: "p-0",
                        attrs: { variant: "danger", size: "lg" },
                        on: {
                          click: function($event) {
                            return _vm.deleteProduct(item.productName)
                          }
                        }
                      },
                      [
                        _c("b-icon", {
                          attrs: { icon: "trash", "font-scale": "1" }
                        })
                      ],
                      1
                    )
                  ]
                }
              }
            ])
          }),
          _vm._l(_vm.productQuantity, function(n) {
            return _c("BundleProduct", {
              key: n,
              on: { product: _vm.setProduct }
            })
          })
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }
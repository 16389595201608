import axios from 'axios'

const errorMessageFives = {
    es:
        'Ups! No podemos alcanzar el servidor. Verifica tu conexión a internet. Verifica también tu conexión a la VPN en caso de que lo requieras.',
    en:
        "Oops! We can't reach the server. Please check your internet connection. Also check your VPN connection if needed."
}

const errorMessageFours = {
    es: 'Recurso no encontrado. Contacta a Soporte o tu equipo de sistemas.',
    en: 'Resource not found. Contact Support or your systems team.'
}

const apiRequest = (baseUrl, headers) => async (method, endpoint, properties = null) => {
    try {
        const url = baseUrl ? `${baseUrl}${endpoint}` : endpoint
        const { status, statusText, data } = await axios({ method, url, data: properties, headers })
        const isImCall = data.data ? data.data : data

        return { status, statusText, data: isImCall }
    } catch ({ response, message }) {
        console.log(response)
        return response
            ? response.status === 404
                ? {
                      status: response.status,
                      statusText: response.statusText,
                      data: errorMessageFours
                  }
                : { status: response.status, statusText: response.statusText, data: response.data }
            : { status: 500, statusText: message, data: errorMessageFives }
    }
}

export default apiRequest

<template>
    <b-container fluid>
        <b-row align-v="center" class="mb-3">
            <b-col cols="6">
                <b-form-input
                    v-model="bundle.bundleName"
                    :placeholder="`${$t('admin.bundles.name')}`"
                ></b-form-input>
            </b-col>
            <b-col>
                <b-button size="sm" variant="primary" @click="phaseQuantity++">
                    {{ $t('admin.bundles.addphase') }}
                </b-button>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <BundlePhase
                    v-for="n in phaseQuantity"
                    :key="n"
                    :quantity="phaseQuantity"
                    :index="n"
                    @updatePhase="updateBundlePhase"
                    :editing-phases="editingPhases"
                    class="mb-3"
                />
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import BundlePhase from './BundlePhase.vue'

export default {
    components: {
        BundlePhase
    },
    props: {
        editingBundle: {
            type: Object,
            default: () => {}
        },
        isEditing: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            phaseQuantity: 1,
            editingPhases: null,
            bundle: {
                isActive: true,
                bundleName: null,
                phases: [],
                isEditing: false
            }
        }
    },
    methods: {
        updateBundlePhase(phase) {
            const index = phase.index - 1
            this.bundle.phases[index]
                ? this.bundle.phases.splice(index, 1, phase)
                : this.bundle.phases.push(phase)
        },
        emitBundle() {
            this.$emit('save-bundle', this.bundle, this.isEditing)
        }
    },
    beforeMount() {
        if (this.editingBundle) {
            this.bundle = { ...this.editingBundle }
            this.editingPhases = this.bundle.phases
            this.phaseQuantity = this.editingPhases.length
        }
    }
}
</script>

import apiRequest from './index'

const imBaseUrl = process.env.VUE_APP_API
const imHeaders = {
    'x-token': `${localStorage.getItem('tkn') || process.env.VUE_APP_TOKEN}`
}

export const imGetRequest = async endpoint => {
    return apiRequest(imBaseUrl, imHeaders)('get', endpoint)
}

export const imPostRequest = async (endpoint, properties) => {
    return apiRequest(imBaseUrl, imHeaders)('post', endpoint, properties)
}

export const imPutRequest = async (endpoint, properties) => {
    return apiRequest(imBaseUrl, imHeaders)('put', endpoint, properties)
}

export const imPatchRequest = async (endpoint, properties) => {
    return apiRequest(imBaseUrl, imHeaders)('patch', endpoint, properties)
}

export const imDeleteRequest = async (endpoint, properties) => {
    return apiRequest(imBaseUrl, imHeaders)('delete', endpoint, properties)
}
